<template>
	<!-- nav -->
	<nav class="nav nav--fit nav--line">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10015')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map"></a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub pick">
			<!-- content -->
			<section class="content" v-if="mmy0120.length > 0">
				<div class="listbox listbox--first">
					<!-- [REST] 숙소 리스트 -->
					<rooms-list :list="mmy0120"/>
				</div>
			</section>
			<section class="content" v-else>
				<div class="row">
					<div class="centered centered--large">
						<div class="centered__wrap">
							<div class="textbox">
								<h3 class="textbox__title" v-html="t('10856')">
									
								</h3>
								<p class="textbox__description">
									{{t('10857')}}
								</p>
								<div class="textbox__button">
									<router-link to="/rooms">
									<button class="btn btn-primary purple" type="button">{{t('10858')}}</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script >
	import { useStore } from 'vuex'
	import { computed, onMounted ,ref} from '@vue/runtime-core'
	import RoomsList from '@/components/RoomsList';
	import { useI18n } from 'vue-i18n' //번역필수 모듈
	import i18n from "@/i18n"; 

	export default {
		setup() {
			const lang = ref();
			const { t }= useI18n() //번역필수 모듈
			const store = useStore();
			const userData = computed(() => store.state.userData);

			onMounted(() => {
				
				lang.value = store.state.app.lang;
				i18n.global.locale.value = store.state.app.lang;
				store.dispatch("mmy01/fetchMmy0120", { proc_cd: '01', mem_id: userData.value.mem_token })})
				;

			const mmy0120 = computed(() => store.state.mmy01.mmy0120);
			
			return {
				mmy0120
				,t
				,i18n
				,lang
			}
		},
		components: {
			RoomsList
		}
	}
</script>